<template>
  <navbar-main />
</template>
<script>
import NavbarMain from "@/modules/navbars/components/NavbarMain.vue";
export default {
  name: "AppLayoutLinks",
  components: {
    NavbarMain,
  },
};
</script>